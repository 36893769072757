.popup-submit-success {
  width: 280px;
  background: var(--white-color);
  padding: 16px 16px 24px;
  border-radius: 14px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  .top-popup {
    display: flex;
    .img-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-left: 44px;
      margin-top: -50px;
    }
    .img-success {
      width: 100px;
      height: 100px;
      filter: drop-shadow(12px 12px 14px rgba(0, 0, 0, 0.1));
    }

    .btn-close {
      width: 24px;
      height: 24px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
  .content-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    .title-popup {
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      padding-left: 24px;
      margin-bottom: 12px;
    }
    .content-popup-text {
      font-size: 16px;
      color: var(--gray5-color);
      line-height: 21px;
      text-align: center;
    }
  }
}
