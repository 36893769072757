.not-found-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 65px;
  .img-wrapper {
    max-width: 1440px;
    margin-top: 100px;
    margin-bottom: 50px;
    .img-404 {
      max-width: 1040px;
      max-height: 450px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .not-found-page {
    padding: 87px 40px 0;
    .img-wrapper {
      margin-top: 100px;
      margin-bottom: 50px;
    }
  }
}
