.jobs-page {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 70px 60px;
  margin-top: 100px;
  .jobs-wrapper{
    max-width: 1440px;
  }
  .header-page {
    display: flex;
    width: 100%;
    .header-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: 16px;
      .title-header {
        font-size: 30px;
        font-weight: 600;
        text-align: start;
        margin-bottom: 50px;
        b {
          color: var(--primary-color);
        }
      }
    }
    .header-right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      object-fit: cover;
    }
  }
  .content-page {
    width: 100%;
    display: flex;
    margin-top: 70px;
  }
  .filter-bar {
    display: flex;
    flex-direction: column;
    flex: 0 0 20%;
    margin-right: 35px;
    padding-top: 100px;
    .header-filter {
      padding: 8px 12px;
      background: var(--gray2-color);
      border-radius: 12px;
      margin-bottom: 16px;
      .icon-add {
        width: 24px;
        height: 24px;
      }
    }
    .list-filter {
      padding: 0 16px;
      border-left: 1px solid var(--primary-color);
    }
    .filter-item {
      width: 100%;
      cursor: pointer;
      .checkbox-input {
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
  .jobs-wrapper {
    width: 100%;
    flex: 1;
    padding: 0 50px;
    .list-jobs {
      margin-top: 40px;
    }
    .item-job {
      max-width: 100%;
      padding: 12px 20px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
      border-radius: 16px;
      background-color: var(--white-color);
      margin-bottom: 24px;
      cursor: pointer;
      &:hover {
        background: var(--gray2-color);
      }
      &:last-child {
        margin-bottom: 0;
      }
      .top-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .left-top-item {
        display: flex;
        align-items: center;
        .icon-location {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .text-location {
          color: var(--gray5-color);
          font-size: 14px;
        }
      }
      .right-top-item {
        display: flex;
        align-items: center;
        .time-work {
          padding: 4px 16px;
          border: 1px solid var(--gray5-color);
          color: var(--gray5-color);
          border-radius: 16px;
          font-size: 14px;
        }
      }
      .content-item {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 8px 0;
        .title-job {
          font-size: 16px;
          font-weight: 600;
        }
      }
      .bottom-item {
        width: 100%;
        display: flex;
        align-items: center;
        .detail-job {
          font-size: 14px;
        }
      }
    }
  }
  .header-list-jobs {
    font-size: 40px;
    font-weight: 600;
    b {
      color: var(--primary-color);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .jobs-page {
    padding: 70px 36px 20px;
    margin-top: 80px;
    .jobs-wrapper {
      padding: 0;
    }
    .header-page {
      margin-top: 50px;
      .header-left {
        width: 60%;
        margin-right: 16px;
        .title-header {
          font-size: 30px;
        }
        .content-header {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .jobs-page {
    margin-top: 80px;
    padding: 70px 10px 20px;
    .header-page {
      display: flex;
      flex-direction: column-reverse;
      .header-left {
        margin-top: 50px;
        width: 100%;
        .title-header {
          margin-bottom: 10px;
        }
        .content-header {
          margin-bottom: 10px;
        }
      }
      .header-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        .img-office {
          width: 80%;
        }
      }
    }
    .jobs-wrapper {
      padding: 0;
    }
  }
}
