.sec6-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f6ff;
}

.sec6-container {
  width: 100%;
  max-width: 1440px;
  padding: 55px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-sec6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .title-text {
      font-size: 48px;
      position: relative;
      &::after {
        content: "";
        width: 36px;
        height: 8px;
        border-radius: 8px;
        background: var(--yellow-color);
        position: absolute;
        left: 47%;
        bottom: -10px;
      }
    }
    .icon-dot {
      width: 66px;
      height: 37px;
      margin-top: 48px;
    }
  }
  .customers {
    display: flex;
    align-items: center;
    margin-top: 40px;
    .btn-action {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 50%;
      background: var(--yellow-color);
      border: none;
      cursor: pointer;
      &:hover {
        background: var(--yellow-color-hover);
      }
      .icon-arrow {
        width: 24px;
        height: 24px;
      }
    }
  }
  .list-customer {
    margin: 0 90px;
    display: flex;
    justify-content: space-between;
    .customer-item {
      flex: 0 0 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      background: var(--white-color);
      border: 2px solid var(--gray3-color);
      border-radius: 12px;
    }
    .content-item {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 48px;
    }
    .info-customer {
      display: flex;
      align-items: center;
    }
    .name-customer {
      font-size: 16px;
      font-weight: 700;
    }
    .img-customer {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
  .customers-mobile-wrapper {
    display: none;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1440px) {
  .sec6-container {
    padding: 66px 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .sec6-container {
    padding: 66px 40px;
  }
}

@media only screen and (max-width: 820px) {
  .sec6-container {
    padding: 66px 40px;
    .customers {
      .list-customer {
        margin: 0 20px;
      }
    }
  }
}

@media only screen and (max-width: 675px) {
  .sec6-container {
    .customers {
      width: 100%;
      overflow-y: auto;
      .list-customer {
        width: 100%;
        display: flex;
        margin: 0;
        .customer-item {
          flex: 0 0 50%;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .sec6-container .customers-mobile-wrapper .list-customer .customer-item {
    flex: 0 0 60%;
  }
}
