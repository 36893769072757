.sec2-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sec2-container {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 200px;
}

.sec2-title {
  text-align: center;
  margin-bottom: 90px;
  .header-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    .title-top {
      color: var(--gray5-color);
      margin-bottom: 4px;
    }
    .title-bottom {
      max-width: 250px;
      position: relative;
      font-size: 32px;
      font-weight: 700;
      .icon-title {
        position: absolute;
        top: -15px;
        right: -25px;
        width: 30px;
        height: 30px;
      }
      &::after {
        content: "";
        position: absolute;
        left: 39%;
        bottom: -10px;
        width: 50px;
        height: 8px;
        border-radius: 8px;
        background: var(--yellow-color);
      }
    }
  }
  .footer-title {
    font-size: 40px;
    font-weight: 700;
    color: var(--gray5-color);
  }
}

.sec2-content {
  max-width: 1040px;
  .list-services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .service-item {
    max-width: 300px;
    height: 300px;
    flex: 0 0 30%;
    padding: 20px;
    border-radius: 12px;
    background: var(--primary-color);
    cursor: pointer;
    position: relative;
    transform: translateX(40%);
    opacity: 0;
    transition: 0.3s ease-in-out;

    &:hover {
      .name-service {
        color: var(--yellow-color);
      }
      .icon-more {
        transform: translateY(-6px);
        opacity: 1;
      }
      .img-service {
        transform: scale(1.1);
      }
    }
    &.mb {
      margin-bottom: 60px;
    }
    &.show {
      transition: 0.3s ease-in-out;
      transform: translateX(0);
      opacity: 1;
      &:nth-child(2) {
        transition-delay: .1s;
      }
      &:nth-child(3) {
        transition-delay: .2s;
      }
      &:nth-child(4) {
        transition-delay: .3s;
      }
      &:nth-child(5) {
        transition-delay: .4s;
      }
      &:nth-child(6) {
        transition-delay: .5s;
      }
    }
  }
  .hidden-icon {
    width: 100%;
    height: 40px;
    background: var(--white-color);
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 1;
  }
  .icon-more {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    transition: all 0.3s ease-out;
    transform: translateY(-60px);
    opacity: 0;
    z-index: 0;
  }
  .img-wrapper {
    width: 100%;
    height: 90%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
  }
  .img-service {
    width: 100px;
    height: 100px;
    transition: all 0.3s ease-out;
  }
  .name-service {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    transition: all 0.3s ease-out;
    color: var(--white-color);
  }
}

@media only screen and (max-width: 1440px) {
  .sec2-container {
    padding: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .sec2-container {
    padding: 100px 40px;
  }
}

@media only screen and (max-width: 820px) {
  .sec2-content .service-item {
    flex: 0 0 48%;
  }
}

@media only screen and (max-width: 675px) {
  .sec2-content .service-item {
    max-width: 100%;
    flex: 1;
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
