.footer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container {
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 200px;
}

.footer-left {
  .logo-footer {
    max-width: 220px;
    max-height: 60px;
  }
}
.footer-content {
  .footer-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    .icon-footer {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .text-footer {
      font-size: 14px;
      color: var(--gray5-color);
      font-family: Arial, Helvetica, sans-serif;
      font-style: normal;
    }
  }
}

.footer-right {
  flex: 0 0 20%;
  display: flex;
  justify-content: space-between;
  .list-link {
    flex: 0 0 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .link-footer {
    text-decoration: none;
    color: var(--black-color);
    font-size: 16px;
    font-weight: 600;
    &:first-child {
      margin-bottom: 26px;
    }
  }
}

.copy-right {
  width: 100%;
  background: var(--black-color);
  padding: 28px 0;
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 1440px) {
  .footer-container {
    padding: 40px 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .footer-container {
    padding: 40px 50px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-container {
    padding: 40px 36px;
    .footer-right {
      margin-left: 30px;
      flex-direction: column;
      .link-footer {
        &:first-child {
          margin-bottom: 30px;
        }
      }
      .list-link {
        &:first-child {
          margin-bottom: 30px;
        }
      }
    }
  }
}
