.detail-jobs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.detail-wrapper {
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 70px 100px;
  .top-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .back-page {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      .text-back {
        text-decoration: underline;
      }
    }
    .icon-back {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .text-back {
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
      b {
        color: var(--primary-color);
        text-decoration: underline var(--primary-color);
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background: var(--gray4-color);
    margin: 20px 0;
  }
  .content-detail-wrapper {
    .top-content-detail {
      .location-time {
        display: flex;
        align-items: center;
        margin-top: 16px;
      }
      .location-work {
        display: flex;
        align-items: center;
        .icon-location {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .text-location {
          color: var(--gray5-color);
          font-size: 14px;
        }
      }
      .time-work {
        padding: 4px 16px;
        border: 1px solid var(--gray5-color);
        color: var(--gray5-color);
        border-radius: 16px;
        font-size: 14px;
        margin-left: 16px;
      }
    }
    .requirement {
      font-size: 16px;
      font-weight: 600;
      margin-top: 20px;
    }
    .main-content-item {
      display: flex;
      flex-direction: column;
    }
    .detail-item {
      margin-top: 40px;
      .detail-item-content {
        margin-top: 16px;
        padding-left: 40px;
        font-size: 16px;
        color: var(--gray8-color);
      }
    }
  }
}

.apply-job {
  display: flex;
  justify-content: space-between;
  border-radius: 30px;
  margin-top: 120px;
  z-index: 1;
  .form-wrapper {
    flex: 0 0 50%;
  }
  .contact-title {
    display: flex;
    flex-direction: column;
    flex: 0 0 40%;
    .title-h2 {
      font-size: 18px;
      font-weight: 700;
      color: var(--primary-color);
    }
    .title-span {
      font-size: 32px;
      font-weight: 700;
      margin: 8px 0 16px;
    }
    .title-p {
      font-size: 14px;
      color: var(--gray7-color);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .detail-wrapper {
    padding: 70px 10px 20px;
  }
}
@media only screen and (max-width: 768px) {
  .detail-wrapper {
    padding: 70px 10px 20px;
  }
  .detail-wrapper .content-detail-wrapper .detail-item .detail-item-content {
    padding-left: 0;
  }
  .form-contact {
    .info-user {
      flex-direction: column;
      .input-name {
        margin-bottom: 16px;
      }
    }
  }
  .apply-job {
    flex-direction: column-reverse;
    .contact-title {
      margin-bottom: 20px;
    }
  }
}
