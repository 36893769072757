.popup-service {
  width: 510px;
  background: var(--white-color);
  border-radius: 12px;
  box-shadow: 12px 12px 14px rgba(0, 0, 0, 0.1);
  .header-popup {
    display: flex;
    padding: 8px;
    border-radius: 12px 12px 0 0;
    &.yellow {
      background: #ffe872;
    }
    &.red {
      background: #ffa07a;
    }
    &.green {
      background: #18f17c;
    }
    .img-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0 12px 32px;
    }
    .img-popup {
      width: 250px;
      height: 250px;
      object-fit: contain;
    }
    .btn-close {
      background: none;
      border: none;
      width: 32px;
      height: 32px;
      cursor: pointer;
      .icon-close {
        width: 24px;
        height: 24px;
      }
    }
  }
  .bottom-popup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    .title-popup {
      font-size: 18px;
      font-weight: 700;
      margin-right: 40px;
    }
    .content-popup {
      font-size: 14px;
      color: var(--gray5-color);
    }
  }
}
