//font poppins
@font-face {
    src: url(../assets/fonts/Poppins-Regular.ttf);
    font-family: Poppins;
    font-weight: 400;
}

@font-face {
    src: url(../assets/fonts/Poppins-Medium.ttf);
    font-family: Poppins;
    font-weight: 500;
}

@font-face {
    src: url(../assets/fonts/Poppins-SemiBold.ttf);
    font-family: Poppins;
    font-weight: 600;
}

@font-face {
    src: url(../assets/fonts/Poppins-Bold.ttf);
    font-family: Poppins;
    font-weight: 700;
}
