.sec4-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-header {
  width: 100%;
  height: 228px;
  background: var(--primary-color);
  padding: 40px 200px;
  display: flex;
  justify-content: center;
  .bg-content-wrapper {
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: flex-start;
  }
  .bg-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    color: var(--white-color);
    opacity: 0;
    transform: translateY(-50%);
    transition: 0.4s ease-in-out;
    transition-delay: 0.6s;
    &.animate {
      opacity: 1;
      transform: translateY(0);
    }
    .title-top {
      margin-bottom: 4px;
    }
    .title-bottom {
      position: relative;
      font-size: 32px;
      font-weight: 700;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 50px;
        height: 8px;
        border-radius: 8px;
        background: var(--yellow-color);
      }
    }
  }
}

.sec4-container {
  width: 100%;
  max-width: 1440px;
  padding: 40px 200px 160px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -140px;
}

.team-slogan {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  white-space: normal;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.4s ease-in-out;
  transition-delay: 0.6s;
  &.animate {
    opacity: 1;
    transform: translateY(0);
  }
  b {
    color: var(--primary-color);
  }
}

.list-member {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 0;
  transform: translateX(-150%);
  transition: 0.6s ease;
  &.animate {
    opacity: 1;
    transform: translateX(0);
  }
  .btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
  }
  .btn-action {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    background: var(--yellow-color);
    border: none;
    cursor: pointer;
    &.prev {
      left: -40px;
    }
    &.next {
      right: -40px;
    }
    &:hover {
      background: var(--yellow-color-hover);
    }
    .icon-arrow {
      width: 24px;
      height: 24px;
    }
  }
  .members {
    max-width: 630px;
    display: flex;
    margin: 0 20px;
  }
  .member-item {
    width: 140px;
    height: 470px;
    filter: drop-shadow(12px 12px 16px rgba(0, 0, 0, 0.15));
    border-radius: 20px;
    position: relative;
    transition: all 0.2s ease-out;
    &:nth-child(2) {
      margin: 0 20px;
    }
    &.show {
      width: 320px;
      .info-member {
        .position-member {
          display: none;
        }
        .position-member-hover,
        .name-member {
          display: block;
        }
      }
    }
    .img-member {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
    .info-member {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 15px;
      border-radius: 0 0 20px 20px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 12.05%,
        #000000 100%
      );
      z-index: 1;
      .position-member {
        color: var(--white-color);
        font-size: 28px;
        font-weight: 700;
        position: relative;
        &::after {
          position: absolute;
          left: 0;
          bottom: -6px;
          content: "";
          width: 36px;
          height: 8px;
          border-radius: 8px;
          background: var(--yellow-color);
        }
      }
      .name-member {
        display: none;
        color: var(--white-color);
        margin-bottom: 5px;
      }
      .position-member-hover {
        display: none;
        color: var(--white-color);
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .bg-header {
    padding: 40px 100px;
  }

  .sec4-container {
    padding: 40px 100px 160px;
  }
}

@media only screen and (max-width: 1024px) {
  .bg-header {
    padding: 40px;
  }

  .sec4-container {
    padding: 40px 40px 160px;
  }
}

@media only screen and (max-width: 924px) {
  .sec4-container {
    flex-direction: column-reverse;
    align-items: flex-start;
    .team-slogan {
      margin-top: 40px;
    }
    .list-member {
      .btn-wrapper {
        bottom: 0;
        right: 0;
      }
    }
  }
}

@media only screen and (max-width: 675px) {
  .bg-header {
    padding: 40px 25px;
  }
  .sec4-container {
    padding: 40px 25px 100px;
    .list-member {
      width: 100%;
      overflow-y: auto;
      flex-wrap: nowrap;
      border-radius: 20px;
      &::-webkit-scrollbar {
        display: none;
      }
      .members {
        max-width: 100%;
        .member-item {
          overflow: hidden;
          filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.15));
          flex: 0 0 60%;
          .name-member,
          .position-member-hover {
            display: block;
          }
          .position-member {
            display: none;
          }
          &.show {
            width: 300px;
            .name-member,
            .position-member-hover {
              display: block;
            }
            .position-member {
              display: none;
            }
          }
        }
      }
    }
    .team-slogan {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 475px) {
  .sec4-container .list-member .members .member-item {
    flex: 0 0 100%;
  }
}
