.sec3-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 200px 200px;
}

.sec3-container {
  max-width: 1440px;
  position: relative;
}

.sec3-content {
  max-width: 1040px;
  display: flex;
  justify-content: space-between;
  .bg-wrapper {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    top: -85px;
    right: 0;
    z-index: -1;
    .img-bg {
      max-width: 425px;
      max-height: 425px;
      &.mr {
        margin-right: 20px;
      }
    }
  }
  .sec3-left {
    flex: 0 0 30%;
    max-width: 430px;
    height: auto;
    transform: translateX(-100%);
    transition: 0.5s cubic-bezier(0.26, 1, 0.48, 1);
    opacity: 0;
    .img-sec3 {
      width: 430px;
      height: 430px;
    }
  }
  .sec3-right {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(100%);
    transition: 0.5s cubic-bezier(0.26, 1, 0.48, 1);
    opacity: 0;
    .header-sec3-right {
      margin-bottom: 16px;
      .title-header-sec3 {
        color: var(--gray5-color);
        font-weight: 700;
        margin-bottom: 16px;
      }
      .content-header-sec3 {
        font-size: 40px;
        font-weight: 700;
        b {
          color: var(--primary-color);
        }
      }
    }
    .bottom-sec3-right {
      font-size: 16px;
      font-weight: 500;
      color: var(--gray7-color);
    }
  }
  &.animate {
    .sec3-left,
    .sec3-right {
      transform: translateX(0);
      transition: 0.5s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 1;
    }
  }
  .mySwiper {
    height: 100%;
    position: relative;
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: var(--gray9-color);
    }
    .swiper-pagination-bullet-active {
      background: var(--black-color);
    }
  }
}

@media only screen and (max-width: 1440px) {
  .sec3-wrapper {
    padding: 200px 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .sec3-wrapper {
    padding: 200px 40px;
  }
}

@media only screen and (max-width: 820px) {
  .sec3-content .sec3-left {
    max-width: 323px;
    .img-sec3 {
      width: 323px;
      height: 323px;
    }
  }
}

@media only screen and (max-width: 675px) {
  .sec3-wrapper {
    padding: 0 40px 100px;
  }
  .sec3-content {
    flex-direction: column-reverse;
    align-items: center;
    .bg-wrapper {
      display: none;
    }
    .sec3-left {
      transition-delay: 0.2s;
    }
    .sec3-right {
      flex: 1;
      margin-bottom: 60px;
    }
  }
}
