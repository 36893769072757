.navbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 5;
  background: var(--white-color);
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
}
.backdrop {
  display: none;
  &.show {
    display: block;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }
}
.navbar-container {
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
  z-index: 5;
  position: relative;
  .navbar-left {
    display: flex;
    align-items: center;
  }
  .logo-img {
    width: 220px;
    height: 60px;
    cursor: pointer;
    transition: all 0.4s ease;
    &.scale {
      width: 200px;
      height: 60px;
    }
  }

  .navbar-right,
  .navbar-mobile {
    display: flex;
    .navbar-ul {
      display: flex;
      align-items: center;
      list-style: none;
      .navbar-li {
        padding: 8px 30px;
        margin-right: 15px;
        font-size: 16px;
        font-weight: 600;
        .navbar-a {
          position: relative;
          text-decoration: none;
          color: var(--black-color);
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            left: 10%;
            bottom: -5px;
            height: 4px;
            width: 0;
            border-radius: 4px;
            transition: all 0.3s ease;
            background: var(--yellow-color);
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover,
        &.select {
          .navbar-a {
            &::after {
              width: 80%;
            }
          }
        }
      }
    }
    .contact-btn {
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 700;
      color: var(--white-color);
      background: var(--primary-color);
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.12);
      border: none;
      white-space: nowrap;
      text-decoration: none;
      cursor: pointer;
      &.hide {
        display: none;
      }
      &:hover {
        background: var(--primary-hover);
      }
    }
  }
  .navbar-right {
    &.hide {
      display: none;
    }
  }
  .navbar-mobile {
    display: none;
    .navbar-ul {
      opacity: 0;
      display: none;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .navbar-container {
    .navbar-right {
      display: none;
    }
    .btn-hamburger {
      display: block;
      background: none;
      border: none;
      cursor: pointer;
    }
    .navbar-mobile {
      display: block;
      position: relative;
      .navbar-ul {
        display: flex;
        flex-direction: column;
        background: var(--white-color);
        border-radius: 12px;
        box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.2);
        padding: 12px;
        position: absolute;
        top: 50px;
        right: 50px;
        transition: 0.25s ease;
        z-index: -1;
        pointer-events: none;
        &.show {
          opacity: 1;
          pointer-events: all;
          z-index: 8;
        }
        .navbar-li {
          margin-right: 0;
          padding: 8px;
          margin-bottom: 8px;
          &:last-child {
            margin-top: 8px;
          }
          .contact-btn {
            width: 100%;
          }
        }
      }
    }
  }
}
