.form-contact {
  width: 100%;
  background: var(--white-color);
  padding: 24px 16px;
  border-radius: 8px;
  border-top: 8px solid var(--primary-color);
  box-shadow: 12px 12px 14px rgba(0, 0, 0, 0.1);
  z-index: 1;
  &.width {
    width: 500px !important;
  }
  .form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 16px;
    .input-form {
      width: 100%;
      background: var(--gray-light);
      padding: 12px 8px;
      border-radius: 8px;
      border: none;
      outline: none;
    }
    .error-msg {
      margin: 4px 0;
      font-size: 12px;
      color: var(--error-color);
    }
  }
  .form-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .btn-cancel {
      margin-right: 24px;
      background: none;
      border: none;
      font-size: 16px;
      font-weight: 700;
      color: var(--gray5-color);
      cursor: pointer;
    }
  }
  .upload-file {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--gray-light);
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 16px;
    outline: none;
    .title-input-upload {
      display: flex;
      flex-direction: column;
      align-items: start;
      .cate-upload {
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: start;
        flex-direction: column;
        color: var(--primary-color);
        margin-bottom: 8px;
      }
      .name-file-upload {
        display: flex;
        align-items: center;
        background: var(--link-color);
        padding: 4px 4px 4px 16px;
        font-size: 16px;
        font-weight: 400;
        color: var(--white-color);
        border-radius: 35px;
        border: 1px solid var(--link-color);
        cursor: pointer;
        text-decoration: none;
        margin-top: 8px;
        .icon-remove {
          width: 24px;
          height: 24px;
          margin-left: 16px;
        }
      }
      .note-support {
        font-size: 12px;
        color: var(--gray5-color);
        text-align: start;
      }
    }
    .upload-input {
      .btn-upload {
        margin-left: 8px;
        padding: 8px 24px;
        border: 1px solid var(--link-color);
        background: var(--white-color);
        color: var(--link-color);
        border-radius: 40px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          background: var(--gray1-color);
        }
      }
      .input-file {
        display: none;
      }
    }
  }
  .PhoneInput {
    display: flex;
    .PhoneInputCountry {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .PhoneInputCountrySelect {
        width: 80px;
        outline: none;
        border: none;
        background: none;
        margin-left: 4px;
        cursor: pointer;
      }
    }
    .PhoneInputInput {
      width: 100%;
      background: var(--gray-light);
      padding: 12px 8px;
      border-radius: 8px;
      border: none;
      outline: none;
    }
  }
  .btn-send {
    width: 140px;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-top: 8px;
    background: var(--primary-color);
    color: var(--white-color);
    border: none;
    border-radius: 60px;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.5s ease;
    position: relative;
    &.sented {
      background: var(--green-color);
      .btn-arrow {
        background: var(--green-color-hover);
        transform: translateX(180%);
        .icon-arrow {
          opacity: 0;
          display: none;
        }
        .icon-check {
          opacity: 1;
          display: block;
        }
      }
      .sent {
        opacity: 1;
      }
      .send {
        opacity: 0;
      }
    }
    .send,
    .sent {
      transition: opacity 0.5 ease;
      z-index: 1;
    }
    .send {
      opacity: 1;
    }
    .sent {
      position: absolute;
      top: 34%;
      left: 20%;
      opacity: 0;
    }
    .btn-arrow {
      transform: translateX(0);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 50%;
      background: var(--primary-hover);
      margin-right: 8px;
      z-index: 2;
      transition: 0.5s ease;
      .icon-arrow,
      .icon-check {
        width: 24px;
        height: 24px;
        transition: opacity 0.5s ease;
      }
      .icon-arrow {
        opacity: 1;
        display: block;
      }
      .icon-check {
        opacity: 0;
        display: none;
      }
    }
  }
}
