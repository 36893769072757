.sec1-wrapper {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.sec1-container {
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 100px;
}
.sec1-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 16px;
  transform: translateX(100%);
  transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
  opacity: 0;
  .sec1-title {
    font-size: 32px;
    font-weight: 700;
    text-align: start;
    margin-bottom: 12px;
    b {
      color: var(--yellow-color);
    }
  }
  .sec1-content {
    display: flex;
    text-align: start;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 18px;
  }
  .contact-btn {
    padding: 12px 36px;
    font-size: 16px;
    font-weight: 600;
    color: var(--white-color);
    background: var(--primary-color);
    box-shadow: 0px 13px 28px rgba(0, 0, 0, 0.2);
    border: none;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background: var(--primary-hover);
    }
  }
  &.animate {
    transform: translateY(0);
    transition: 1s cubic-bezier(0.26, 1, 0.48, 1);
    opacity: 1;
  }
}
.sec1-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(100%);
  transition: 0.5s cubic-bezier(0.26, 1, 0.48, 1);
  opacity: 0;
  object-fit: cover;
  .img-sec1 {
    max-width: 525px;
    max-height: 525px;
    animation: MoveUpDown 3s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
    animation-delay: 2s;
  }
  &.animate {
    transform: translateX(0);
    transition: 1s cubic-bezier(0.26, 1, 0.48, 1);
    transition-delay: 0.4s;
    opacity: 1;
  }
}

@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1024px) {
  .sec1-container {
    padding: 60px 40px;
  }
}

@media only screen and (max-width: 600px) {
  .sec1-container {
    align-items: flex-start;
    flex-direction: column;
  }
  .sec1-left {
    width: 100%;
    margin-bottom: 40px;
  }
  .sec1-right {
    width: 100%;
  }
}
