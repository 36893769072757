.sec5-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--gray-light);
}

.sec5-container {
  width: 100%;
  max-width: 1440px;
  padding: 70px 200px 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sec5-content {
  width: 100%;
  .icon-header {
    width: 45px;
    height: 45px;
    opacity: 0;
    transition: 0.5s ease;
  }
  .sec5-title {
    margin-top: 95px;
    transform: translateY(30%);
    opacity: 0;
    transition: 0.4s ease;
    .title-h2 {
      font-size: 24px;
      font-weight: 700;
      color: var(--gray5-color);
    }
    .title-span {
      font-size: 40px;
      font-weight: 700;
      margin: 8px 0 16px;
    }
    .title-p {
      font-size: 16px;
      font-weight: 400;
      color: var(--gray5-color);
    }
  }
  .sec5-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 70px;
  }
  .sec5-item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 12px 12px 14px rgba(0, 0, 0, 0.1);
    flex: 0 0 23%;
    transform: translateY(30%);
    opacity: 0;
    transition: 0.4s ease;
    &:nth-child(2){
      transition-delay: .1s;
    }
    &:nth-child(3){
      transition-delay: .2s;
    }
    &:nth-child(4){
      transition-delay: .3s;
    }
    .icon-item {
      width: 56px;
      height: 56px;
      margin-right: 12px;
    }
    .info-item {
      display: flex;
      flex-direction: column;
      .name-item {
        font-size: 14px;
        font-weight: 600;
        color: var(--gray5-color);
        margin-bottom: 12px;
      }
      .count-item {
        font-size: 36px;
        font-weight: 700;
        font-family: sans-serif;
      }
    }
  }
  &.animate {
    .icon-header {
      opacity: 1;
    }
    .sec5-title {
      transform: translateX(0);
      opacity: 1;
    }
    .sec5-item {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .sec5-container {
    padding: 70px 100px 160px;
  }
}

@media only screen and (max-width: 1024px) {
  .sec5-container {
    padding: 70px 40px 160px;
  }
  .sec5-content {
    .sec5-item {
      flex: 0 0 49%;
      &:first-child,
      &:nth-child(2) {
        margin-bottom: 24px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .sec5-container {
    padding: 50px 10px 100px;
  }
  .sec5-content {
    .sec5-item {
      flex: 0 0 100%;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
