.popup-confirm {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  &.bgBlack {
    background: var(--black-color);
  }
  .popup-confirm-content {
    z-index: 2;
    border-radius: 12px;
    position: fixed;
    top: 80px;
    &.center {
      position: fixed;
      top: 23%;
    }
    &.top {
      top: 30px !important;
    }
    &.fullHeight {
      height: 10vh;
    }
  }
  .back-drop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.4);
    z-index: 1;
  }
}
