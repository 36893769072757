.contact-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--gray-light);
}

.contact-container {
  max-width: 1440px;
  padding: 180px 200px;
  position: relative;
}

.contact-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .form-wrapper {
    flex: 0 0 50%;
    transform: translateX(-100%);
    transition: 0.5s cubic-bezier(0.26, 1, 0.48, 1);
    transition-delay: 0.2s;
    opacity: 0;
    z-index: 1;
  }
  .contact-title {
    display: flex;
    flex-direction: column;
    flex: 0 0 40%;
    z-index: 1;
    transform: translateX(-150%);
    transition: 0.5s cubic-bezier(0.26, 1, 0.48, 1);
    opacity: 0;
    .title-h2 {
      font-size: 18px;
      font-weight: 700;
      color: var(--primary-color);
    }
    .title-span {
      font-size: 32px;
      font-weight: 700;
      margin: 8px 0 16px;
    }
    .title-p {
      font-size: 14px;
      color: var(--gray7-color);
    }
  }

  &.animate {
    .form-wrapper {
      transform: translateX(0);
      opacity: 1;
    }
    .contact-title {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.contact-bg {
  width: 350px;
  height: 320px;
  position: absolute;
  right: 100px;
  bottom: 50px;
  z-index: 0;
}

@media only screen and (max-width: 1440px) {
  .contact-container {
    padding: 180px 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .contact-container {
    padding: 180px 40px;
  }
}

@media only screen and (max-width: 768px) {
  .form-wrapper {
    .info-user {
      flex-direction: column;
      .input-name {
        margin-bottom: 16px;
      }
    }
  }
  .contact-content {
    flex-direction: column-reverse;
    .contact-title {
      margin-bottom: 20px;
    }
  }
}
